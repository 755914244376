import { gsap } from "gsap";    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { debounce, throttle } from "./util.js";

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

gsap.registerPlugin(ScrollTrigger);

const initStickySidebar = function() {

    var sharePositionTop = false;
    var windowWidth = $(window).width();
    var sidebar = $(".sidebar.sidebar-form");

    if (!$(sidebar).length) {
        return;
    }

    $(window).on("resize", function() {
        sidebar = $(".sidebar.sidebar-form");
        if (!$(sidebar).length) {
            return;
        }    
        windowWidth = $(window).width();
        $(sidebar).width($(sidebar).parent().width());
        sharePositionTop = $(".share").offset().top - $(".sidebar.sidebar-form").outerHeight();
    });


    const decideWhereItsStuck = function() {
        sidebar = $(".sidebar.sidebar-form");
        var scrollTop = $(document).scrollTop();

        if (windowWidth < 768 || !$(sidebar).length) {
            return;
        }

        if (scrollTop > $(".sticky-sidebar-wrap").offset().top + $(sidebar).outerHeight() && scrollTop < sharePositionTop - 80) {
            $(".sidebar").addClass("stuck");
            $(sidebar).removeClass("absolute");
        } else if (scrollTop < $(".sticky-sidebar-wrap").offset().top - 80 && scrollTop < sharePositionTop) {
            $(".sidebar").removeClass("stuck");
            $(sidebar).removeClass("absolute");
        } else if (scrollTop >= sharePositionTop && scrollTop >= $(".sticky-sidebar-wrap").offset().top) {
            $(sidebar).removeClass("stuck");
            $(sidebar).addClass("absolute");
        } else if (scrollTop < sharePositionTop - 80 && scrollTop >= $(".sticky-sidebar-wrap").offset().top) {
            $(sidebar).addClass("stuck");
            $(sidebar).removeClass("absolute");
        }
    }

    $(document).on("scroll", throttle(function() {
        decideWhereItsStuck();
    }, 10));

    window.setTimeout(function() {
        sidebar = $(".sidebar.sidebar-form");
        if (!$(sidebar).length) {
            return;
        }    
        sharePositionTop = $(".share").offset().top - $(".sidebar.sidebar-form").outerHeight();
        decideWhereItsStuck();
    }, 2000);

    $(window).trigger("resize");

};

export default initStickySidebar;